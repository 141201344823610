<template>
<ion-card>
  <ion-card-header>
    <ion-card-title>
      <h2 style="vertical-align: baseline;">
        {{ this.title }}
        <!-- <ion-icon :icon="trashOutline"
                  style="color: red; cursor: pointer; height: 24px; vertical-align: baseline;"
                  @click="onDayClick(day)"
        /> -->
      </h2>
    </ion-card-title>
  </ion-card-header>
  <ion-card-content class="no-padding-mobile">
    <div style="display: flex; justify-content: space-around;">
      <ion-item slot="start" lines="full" class="input-wrapper display-content">
        <ion-label class="label" position="floating">Heure de début</ion-label>
        <ion-datetime
            displayFormat="HH:mm"
            locale='fr-FR'
            minuteValues="0,15,30,45"
            show-default-buttons="true"
            size="cover"
            done-text="Confirmer"
            cancel-text="Retour"
            v-model="localDay.beginTime"
            @ionChange="this.updateLocalDayToParent"
        />
      </ion-item>
      <ion-item slot="end" class="input-wrapper display-content">
        <ion-label class="label" position="floating">Heure de fin</ion-label>
        <ion-datetime displayFormat="HH:mm"
                      locale='fr-FR'
                      minuteValues="0,15,30,45"
                      show-default-buttons="true"
                      done-text="Confirmer"
                      cancel-text="Retour"
                      v-model="localDay.endTime"
                      @ionChange="this.updateLocalDayToParent"
        />
      </ion-item>
    </div>
    <div style="display: flex; justify-content: space-around;">
      <ion-item slot="start" class="input-wrapper display-content">
        <ion-label class="label" position="floating">Temps de repos</ion-label>
        <ion-datetime displayFormat="HH:mm"
                      locale='fr-FR'
                      hourValues="0,1,2"
                      minuteValues="0,15,30,45"
                      show-default-buttons="true"
                      size="cover"
                      done-text="Confirmer"
                      cancel-text="Retour"
                      v-model="localDay.pauseHours"
                      @ionChange="this.updateLocalDayToParent"
        />
      </ion-item>
      
    </div>

    <div class="checkboxes-container">
      <div class="checkbox-input" style="margin-right: 30px">
        <ion-label position="fixed" type="dark" class="label" style="flex: auto; width: 9rem">Horaire flexible
          ?
        </ion-label>
        <ion-checkbox @click="localDay.isFlexible = !localDay.isFlexible" mode="ios" @ionChange="this.updateLocalDayToParent"></ion-checkbox>
      </div>

      <div class="checkbox-input">
        <ion-label position="fixed" type="dark" class="label" style="flex: auto; width: 9rem">Titulaire
          présent ?
        </ion-label>
        <ion-checkbox @click="localDay.ownerStatus = !localDay.ownerStatus" mode="ios" @ionChange="this.updateLocalDayToParent"></ion-checkbox>
      </div>
     
    </div>
    <div>
        <ion-item slot="start" class="input-wrapper display-content">
        <ion-label style="margin-top: 10px;" class="label" position="floating"><h2>Ajoutez une prime journalière à l'intérimaire</h2></ion-label>
        <ion-label style="margin-bottom: 10px;" class="label" position="floating"><h2>(permet d'obtenir plus facilement des candiats)</h2></ion-label>
        <ion-select interface="alert" cancel-text="Annuler" placeholder="Veuillez choisir"
                    v-model="localDay.increase"
                    @ionChange="this.updateLocalDayToParent">
          <ion-select-option value="0">0€/jour</ion-select-option>
          <ion-select-option value="1">1€/jour</ion-select-option>
          <ion-select-option value="2">2€/jour</ion-select-option>
          <ion-select-option value="3">3€/jour</ion-select-option>
          <ion-select-option value="4">4€/jour</ion-select-option>
          <ion-select-option value="5">5€/jour</ion-select-option>
          <ion-select-option value="6">6€/jour</ion-select-option>
          <ion-select-option value="7">7€/jour</ion-select-option>
          <ion-select-option value="8">8€/jour</ion-select-option>
          <ion-select-option value="9">9€/jour</ion-select-option>
          <ion-select-option value="10">10€/jour</ion-select-option>
        </ion-select>
      </ion-item>
      </div>
    <hr/>
    <ion-text type="dark" class="label ion-margin-top checkboxes-container" style="margin-top: 15px;margin-bottom: 15px;">
      <h3>
      Choisissez votre offre : 
      </h3>
    </ion-text>
    <ion-list>
      <ion-radio-group :value="localDay.pricing" style="display: flex;justify-content:space-around;" @ionChange="updatePricing($event, index)">
        <ion-item  v-for="(item, index) in 3" :key="index" class="card-content-wrapper">
          <label class="radio-card">
            <ion-radio :value="index" ></ion-radio>
    
             
              <div class="card-content">
                <h3 :class="['ion-no-padding', 'ion-margin', 'pricing-' + index]" id="pricing">{{ pricingToString[index] }}</h3>
                <ul class="w-100 ion-no-padding">
                <li
                  v-for="advantage in pricingToAdvantages[index]"
                  :key="advantage"
                  style="list-style-type: none"
                  :class="['w-100', 'pricing-' + index]"
                >
                • {{ advantage }}
                </li>
              </ul>
              </div>
         
          </label>
         </ion-item>
        </ion-radio-group>
      </ion-list>

    <ion-text color="dark">
      <p style="margin: 20px 0 10px 0">Choisissez vos intérimaires</p>
    </ion-text>
    <div class="typologie-container">
      <ion-item>
        <ion-label class="label" position="floating">Pharmacien ({{displayPricing("pharmacist",localDay.pricing)}}€/h)</ion-label>
        <ion-input class="typologie-input" type="number" v-model="localDay.pharmacienSubstitute" @ionChange="this.updateLocalDayToParent"
                   :value="localDay.pharmacienSubstitute" min="0"/>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="floating">Préparateur ({{displayPricing("technician",localDay.pricing)}}€/h)</ion-label>
        <ion-input class="typologie-input" type="number" v-model="localDay.preparers" :value="localDay.preparers" @ionChange="this.updateLocalDayToParent"
                   min="0"/>
      </ion-item>
    </div>
    <div class="typologie-container">
      <ion-item>
        <ion-label class="label" position="floating">Étudiant en pharmacie ({{displayPricing("student",localDay.pricing)}}€/h)</ion-label>
        <ion-input class="typologie-input" type="number" v-model="localDay.students" :value="localDay.students" @ionChange="this.updateLocalDayToParent"
                   min="0"/>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="floating">Tous profil (entre {{displayPricing("student",localDay.pricing)}}€/h et {{displayPricing("pharmacist",localDay.pricing)}}€/h)</ion-label>
        <ion-input class="typologie-input" type="number" v-model="localDay.pharmacienPreparersStudentsSubstitute" @ionChange="this.updateLocalDayToParent"
                   :value="localDay.pharmacienPreparersStudentsSubstitute" min="0"/>
      </ion-item>
    </div>
    <div class="typologie-container">
      <ion-item>
        <ion-label class="label" position="floating">Préparateur ou Pharmacien (entre {{displayPricing("technician",localDay.pricing)}}€ et {{displayPricing("pharmacist",localDay.pricing)}}€/h)</ion-label>
        <ion-input class="typologie-input" type="number" v-model="localDay.pharmacienPreparerSubstitute" @ionChange="this.updateLocalDayToParent"
                   :value="localDay.pharmacienPreparerSubstitute" min="0"/>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="floating">Préparateur ou Étudiant (entre {{displayPricing("student",localDay.pricing)}}€ et {{displayPricing("technician",localDay.pricing)}}€/h)</ion-label>
        <ion-input class="typologie-input" type="number" v-model="localDay.preparersStudents" @ionChange="this.updateLocalDayToParent"
                   :value="localDay.preparersStudents" min="0"/>
      </ion-item>
    </div>

  </ion-card-content>
</ion-card>
</template>
<script>
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    // IonContent,
    IonDatetime,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonText,
  } from '@ionic/vue';
  import {pricingToAdvantages, pricingToString, pricingToAmount, subscriberPricingToAmount}  from "@/services/utils";
  export default {
    name: 'AddMissionCard',
    components: {
      IonText,IonDatetime,IonInput,IonItem,IonLabel,IonList,
      IonRadio,IonRadioGroup,IonSelect,IonSelectOption,IonCheckbox,
      IonCardTitle, IonCardHeader, IonCard, IonCardContent
    },
    props: {
      updateRecurrentDays: Function,
      isRecurent: Boolean,
      isSubscriber: Boolean,
      title: String,
      dayNumber: Number,
      day: Object
    },
    methods: {
      displayPricing(candidateType, pricingIndex){
      if(this.isSubscriber)
        return subscriberPricingToAmount[candidateType][pricingIndex]
      return pricingToAmount[candidateType][pricingIndex]
      },
      updatePricing(event, day) {
          //console.log('Current value:', JSON.stringify(event.detail.value));
          this.days[day].pricing = event.detail.value

        },
      updateLocalDayToParent(){
        console.log("là")
        this.updateRecurrentDays(this.localDay,this.dayNumber)
      }
    },
    data() {
      return{
        localDay : this.day,
        pricingToAdvantages,
        pricingToString,
        pricingToAmount,
        subscriberPricingToAmount,
      }
    },
}
</script>

<style scoped>
.typologie-container > * {
  display: contents !important;
}

.typologie-container > *, .input-wrapper {
  width: 40%;
}

.typologie-input {
  align-items: flex-end !important;
}

.checkboxes-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0 12px;
}

.checkbox-input {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.display-content {
  display: contents !important;
}

@media screen and (max-device-width: 640px), screen and (max-width: 640px) {
  .checkboxes-container {
    flex-direction: column;
    margin: 0;
  }

  .checkbox-input {
    margin: 10px 0 10px 0;
  }

  .no-padding-mobile {
    padding: 10px 5px 10px 5px !important;
  }

  .typologie-container > *, .input-wrapper {
    width: 40%;
  }
}

.typologie-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.menuTitleFont {
  padding: 50px;
  margin-top: -20px
}

ion-button {
  margin-top: 20px
}

.validate {
  margin: 10px 0 30px 0;
}



ion-range {
  --bar-height: 8px;
  --knob-size: 40px;
}

#pricing {
  margin: 0 0 0 10px;
  font-weight: bold;
  font-size: 24px;
}


 .card-content-wrapper {
  background: #fff;
  border-radius: 5px;
  max-width: 280px;
  /* min-height: 130px; */
  padding-bottom: 0px;
  display: grid;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  transition: 200ms linear;
}
 .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
}
.check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}
 input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
 .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3057d5;
}

input[type=radio] + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #d1d1d1;
}


input[type=radio]:checked + .card-content-wrapper .check-icon {
  background: #3057d5;
  border-color: #3057d5;
  transform: scale(1.2);
}
 input[type=radio]:checked + .card-content-wrapper .check-icon:before {
  transform: scale(1);
  opacity: 1;
}
input[type=radio]:focus + .card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3056d5;
}
.card-content img {
  margin-bottom: 10px;
}
 .card-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #1f2949;
  margin-bottom: 10px;
}
 .card-content h5 {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #686d73;
}


</style>