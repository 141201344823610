const pricingToString: { [id: number]: string } = {
  0: 'Basic',
  1: 'Basic+',
  2: 'Premium',
}

const stringPricingToNumber: { [id: string]: number } = {
  basic: 0,
  'basic_plus': 1,
  premium: 2,
};

const pricingToAdvantages: { [id: number]: string[] } = {
  0: ["Tous niveaux"],
  1: ["Profil Autonome"],
  2: ["Profil Expert"],
}
// conformement au pricing de site internet
const pricingToAmount: { [type: string]: number[] } = {
  "student": [34, 37, 38.90],
  "technician": [36, 40, 44],
  "pharmacist" : [53, 58, 63],
}

// conformement au pricing de l'abonnement de site internet
const subscriberPricingToAmount: { [type: string]: number[] } = {
  "student": [28, 29, 31],
  "technician": [29, 33, 38],
  "pharmacist" : [45, 52, 57],
}

function stringPricingToString(pricing: string) {
  return pricingToString[stringPricingToNumber[pricing]];
}

function stringPricingNextPricing(pricing: string) {
  let newIndex = stringPricingToNumber[pricing] + 1;
  if (newIndex > 2) {
    return newIndex = 2;
  }
  return pricingToString[newIndex];
}

export {
  pricingToString,
  pricingToAdvantages,
  stringPricingToNumber,
  pricingToAmount,
  subscriberPricingToAmount,
  stringPricingToString,
  stringPricingNextPricing
};
